// Option to add a gradient by adding another color
// but need to slightly modify function in ThreeJsBackground
export default [
  {
    "id": 0,
    "color": "#FF9999"
  },
  {
    "id": 1,
    "color": "#BA33FF"
  },
  {
    "id": 2,
    "color": "#7CB2E8"
  },
  {
    "id": 3,
    "color": "#40e2a0"
  }
]